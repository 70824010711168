@font-face {
  font-family: "Neue Haas Unica Pro";
  src: url("./assets/fonts/NeueHaasUnicaPro-Black.woff2") format("woff2"),
    url("./assets/fonts/NeueHaasUnicaPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Unica Pro";
  src: url("./assets/fonts/NeueHaasUnicaPro-Thin.woff2") format("woff2"),
    url("./assets/fonts/NeueHaasUnicaPro-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Unica Pro";
  src: url("./assets/fonts/NeueHaasUnicaPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/NeueHaasUnicaPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Unica Pro";
  src: url("./assets/fonts/NeueHaasUnicaPro-Bold.woff2") format("woff2"),
    url("./assets/fonts/NeueHaasUnicaPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bookmania";
  src: url("./assets/fonts/Bookmania-Regular.woff2") format("woff2"),
    url("./assets/fonts/Bookmania-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5em;
  font-family: "Neue Haas Unica Pro", sans-serif;
  color: rgba(0, 0, 0, 0.8);
}

/* typography */

h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4em;
  color: black;
}

h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 100;
  line-height: 1.4em;
  color: black;
}

p {
  margin: 20px 0;
}

h2 + h3,
h2 + p {
  margin-top: 10px;
}

a {
  color: black;
}

button,
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  border: none;
  color: black;
  font-weight: bold;
  white-space: nowrap;
  text-decoration: none;
}

/* helpers */

.hide {
  opacity: 0;
}

.small {
  margin-top: 0;
  margin-bottom: 20px;
}

.strike {
  text-decoration: line-through;
}

/* layout */

.section {
  position: relative;
  padding-top: 50px;
}

.section:not(.inverted) + .section.inverted {
  margin-top: 50px;
}

.section.inverted {
  padding-top: 40px;
  padding-bottom: 10px;
  background-color: black;
}

.section.inverted h2,
.section.inverted h3,
.section.inverted strong {
  color: hsla(0, 0%, 100%, 0.9);
}

.section.inverted p,
.section.inverted a {
  color: hsla(0, 0%, 100%, 0.75);
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.content {
  padding: 0 20px;
}

.image-control {
  display: none;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 300ms ease-out;
  transition-delay: 300ms;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vw;
  margin: 30px -20px;
  pointer-events: none;
}

/* lazy man's lazy loading whaaaaaat */
.image-container:not(.load):not(.feature) .image {
  background-image: none !important;
}

.image-container + .image-container {
  margin-top: -20px;
}

.image-container.feature .image {
  opacity: 1;
  transition-delay: 0ms;
}

.cta {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  text-align: center;
}

.cta .button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  margin: 0 2px;
  padding: 10px 15px;
  font-weight: bold;
}

.cta .button img {
  position: relative;
  top: 2px;
  width: 18px;
  height: 18px;
}

.cta .image-control {
  display: none;
}

.scroll-progress-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: black;
}

.scroll-progress {
  position: relative;
  height: 4px;
  background-color: #ffb22a;
}

.scroll-prompt {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.scroll-prompt a {
  display: block;
  animation: bob 2000ms ease-in-out infinite;
}

.scroll-prompt img {
  width: 40px;
  height: 40px;
}

/* sections */

#intro {
  min-height: 100vh;
}

#intro .image {
  opacity: 1 !important;
}

#intro .brand {
  margin-top: 50px;
}

#intro .logo {
  height: 50px;
  max-width: 100%;
  margin-bottom: 5px;
}

/* icons */

.arrow-bottom {
  transform: rotate(0);
}

.arrow-right {
  transform: rotate(-90deg);
}

.arrow-left {
  transform: rotate(90deg);
}

.arrow-top {
  transform: rotate(180deg);
}

/* images */

.image-beer {
  background-image: url(./assets/images/beer.jpg);
}
.image-bikes {
  background-image: url(./assets/images/bikes.jpg);
}
.image-cafecouch {
  background-image: url(./assets/images/cafecouch.jpg);
}
.image-cafecouch1 {
  background-image: url(./assets/images/cafecouch1.jpg);
}
.image-cafetable {
  background-image: url(./assets/images/cafetable.jpg);
}
.image-cafetable1 {
  background-image: url(./assets/images/cafetable1.jpg);
}
.image-cafetable2 {
  background-image: url(./assets/images/cafetable2.jpg);
}
.image-cafetable3 {
  background-image: url(./assets/images/cafetable3.jpg);
}
.image-cafetable4 {
  background-image: url(./assets/images/cafetable4.jpg);
}
.image-couch {
  background-image: url(./assets/images/couch.jpg);
}
.image-couch2 {
  background-image: url(./assets/images/couch2.jpg);
}
.image-desk {
  background-image: url(./assets/images/desk.jpg);
}
.image-furniture {
  background-image: url(./assets/images/furniture.jpg);
}
.image-furniture1 {
  background-image: url(./assets/images/furniture1.jpg);
}
.image-gameroom {
  background-image: url(./assets/images/gameroom.jpg);
}
.image-gameroom1 {
  background-image: url(./assets/images/gameroom1.jpg);
}
.image-gaming {
  background-image: url(./assets/images/gaming.jpg);
}
.image-hat {
  background-image: url(./assets/images/hat.jpg);
}
.image-main {
  background-image: url(./assets/images/main.jpg);
}
.image-meeting {
  background-image: url(./assets/images/meeting.jpg);
}
.image-pingpong {
  background-image: url(./assets/images/pingpong.jpg);
}
.image-plants {
  background-image: url(./assets/images/plants.jpg);
}
.image-space {
  background-image: url(./assets/images/space.jpg);
}
.image-teapot {
  background-image: url(./assets/images/teapot.jpg);
}
.image-wall {
  background-image: url(./assets/images/wall.jpg);
}
.image-wall2 {
  background-image: url(./assets/images/wall2.jpg);
}
.image-whiteboard {
  background-image: url(./assets/images/whiteboard.jpg);
}
.image-work {
  background-image: url(./assets/images/work.jpg);
}
.image-workout {
  background-image: url(./assets/images/workout.jpg);
}
.image-workout1 {
  background-image: url(./assets/images/workout1.jpg);
}
.image-workout3 {
  background-image: url(./assets/images/workout3.jpg);
}
.image-zeroday {
  background-image: url(./assets/images/zeroday.jpg);
}

/* tablet behavior */

@media (min-width: 480px) and (orientation: portrait) {
  #intro .brand {
    margin-top: 100px;
  }

  .content {
    padding: 0 100px;
  }

  .image-container {
    margin: 30px -100px;
  }
}

/* desktop behavior */

@media (min-width: 800px) and (orientation: landscape) {
  #intro h3 {
    min-height: 70%;
  }

  #intro .brand {
    position: relative;
    padding-right: 75px;
    padding-bottom: 35px;
    border-right: 4px solid #ffb22a;
  }

  #intro .content {
    min-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #license {
    padding-bottom: 100px;
  }

  h2,
  h3 {
    font-size: 24px;
  }

  .content {
    width: 50%;
    padding: 0 50px;
  }

  .scroll-prompt {
    display: block;
  }

  .scroll-progress-container {
    position: relative;
  }

  .scroll-progress-container.fixed {
    position: fixed;
  }

  .image {
    opacity: 0;
  }

  .image-container {
    z-index: 9999;
    position: fixed;
    bottom: 0;
    left: 50%;
    width: calc(50% - 50px);
    height: calc(100% - 50px);
    max-width: calc(1400px / 2 - 50px);
    margin: 0;
  }

  .cta {
    z-index: 9999999;
    position: fixed;
    left: 50%;
    bottom: 80px;
    max-width: calc(1400px / 2 - 50px);
    width: calc(50% - 50px);
    white-space: nowrap;
  }

  .cta .image-control {
    display: inline-block;
    vertical-align: top;
    padding: 0 !important;
    width: 45px;
    text-align: center;
    background: none;
    transition: all 200ms;
    cursor: pointer;
  }

  .cta .image-control:hover {
    background-color: black;
  }

  .cta .image-control img {
    position: relative;
    margin: 0 auto;
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1200px) {
  .content {
    padding-left: 75px;
  }

  h2,
  h3 {
    font-size: 30px;
  }

  .cta .button {
    height: 60px;
    font-size: 18px;
    padding: 16px 20px;
  }

  .cta .button img {
    width: 24px;
    height: 24px;
  }

  .cta .image-control {
    width: 60px;
  }
}

@keyframes bob {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
